import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-start gap-y-4 overflow-auto scrollbar pr-1" }
const _hoisted_2 = { class: "text-center w-full" }
const _hoisted_3 = { class: "text-center text-sm text-grey-darker" }
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "text-sm mt-3 text-grey-darker" }
const _hoisted_6 = { class: "text-sm mt-3 text-grey-darker" }
const _hoisted_7 = { class: "text-sm mt-3 text-grey-darker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_default = _resolveComponent("input-default")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_auth_container = _resolveComponent("auth-container")!

  return (_openBlock(), _createBlock(_component_auth_container, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t("auth.reset_password")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("auth.reset_password_text")), 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_input_default, {
            class: "w-full mb-5",
            id: 'password',
            name: 'password',
            type: 'password',
            modelValue: _ctx.passwordInput.password,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.passwordInput.password) = $event)),
            hasPasswordVisibilityToggle: true,
            onEnter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.userResetPassword())),
            label: _ctx.$t('auth.password')
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_input_default, {
            hasMeta: false,
            class: "w-full",
            id: 'password_confirmation',
            metaId: 'email',
            name: 'password_confirmation',
            type: 'password',
            modelValue: _ctx.passwordInput.repeatPassword,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.passwordInput.repeatPassword) = $event)),
            hasPasswordVisibilityToggle: true,
            onEnter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.userResetPassword())),
            label: _ctx.$t('auth.repeat_password')
          }, null, 8, ["modelValue", "label"]),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("settings.change_password_text1")), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("settings.change_password_text2")), 1),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("settings.change_password_text3")), 1)
        ])
      ]),
      _createVNode(_component_custom_button, {
        id: 'reset-password-button',
        class: "w-full mt-5",
        buttonStyle: _ctx.buttonStyle,
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.userResetPassword()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("auth.change_password")), 1)
        ]),
        _: 1
      }, 8, ["buttonStyle"]),
      _createVNode(_component_custom_button, {
        id: 'reset-password-cancel-button',
        class: "w-full mt-5",
        isPlainButton: true,
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push({ name: _ctx.cancelRoute })))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("auth.cancel")), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}