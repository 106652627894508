
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import InputDefault from "@/components/inputs/inputDefault/InputDefault.vue";
import AuthContainer from "./containers/AuthContainer.vue";
import { storeToRefs } from "pinia";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useAuthStore } from "../../store/auth";
import { useHelpersStore } from "@/store/helpers";
import { getCookie } from "@/common/cookies";
import { useRoute, useRouter } from "vue-router";
import { findRouteGetParameter } from "@/common/route_functions";
import { useI18n } from "vue-i18n";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
export default defineComponent({
    name: "ResetPassword",
    components: { AuthContainer, CustomButton, InputDefault },
    setup() {
        const { t } = useI18n();
        const passwordInput = ref<any>({ password: "", repeatPassword: "" });

        const { resettedPassword } = storeToRefs(useAuthStore());
        const { resetPassword } = useAuthStore();
        const { setLoadingId, removeLoadingId, setToastMessage } =
            useHelpersStore();
        const router = useRouter();

        const emailParam = findRouteGetParameter("email");
        const tokenParam = findRouteGetParameter("token");

        async function userResetPassword(): Promise<void> {
            setLoadingId("reset-password-button");
            if (emailParam && tokenParam)
                await resetPassword({
                    token: tokenParam,
                    email: emailParam,
                    password: passwordInput.value.password,
                    password_confirmation: passwordInput.value.repeatPassword,
                });
            removeLoadingId("reset-password-button");
        }

        const route = useRoute();
        const buttonStyle = computed(() => {
            let style = {};
            if (route.name?.toString().includes("CustomerAuth")) {
                style = {
                    standardBackgroundColor: "black-lightest",
                    standardBackgroundHoverColor: "black-light",
                    standardBackgroundPressedColor: "black-light",
                    standardBorderPressedColor: "black-light",
                    standardBorderFocusColor: "black-light",
                };
            }
            return style;
        });
        const cancelRoute = computed(() => {
            let routeName = "AuthLogin";
            if (route.name?.toString().includes("CustomerAuth")) {
                routeName = "CustomerAuthLogin";
            }
            return routeName;
        });

        onMounted(() => {
            if (getCookie("logged_in")) {
                router.push({ name: "NormAdministration" });
            }
        });

        return {
            resettedPassword,
            passwordInput,
            userResetPassword,
            buttonStyle,
            cancelRoute,
        };
    },
});
